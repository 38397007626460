import React from 'react';
import classnames from 'classnames';
import { checkNested } from 'MODULES/checkNested';
import { LOT_VISIBILITY } from 'MODULES/LOT_VISIBILITY';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBookmark } from 'ACTIONS/bookmarks/toggleBookmark';
import { useRouteMatch, useHistory } from 'react-router';
import { categoriesGetPpByIdSelector } from 'MODULES/reselect/selectors/categories';
import { TOGGLE_BOOKMARK } from 'ACTIONS';
import { isUserLoggedIn } from 'MODULES/reselect/selectors/user';
import findIndex from 'lodash/findIndex';

const PpBookmarkButton = ({ i18n }) => {
    const isLoggedIn = useSelector(isUserLoggedIn);
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user.userData);
    const match = useRouteMatch();
    const ppId = match.params.ppid;
    const pp = useSelector((state) => categoriesGetPpByIdSelector(state, ppId));
    const isFetching = useSelector(state => state.ui.isFetching[TOGGLE_BOOKMARK][ppId]);
    const history = useHistory();
    const findBoomarkedFavoriteByUser = userData && pp.favorites ? findIndex(pp.favorites.data, { id: userData.id }) : -1;
    const isBookmarked = pp.isBookmarked || findBoomarkedFavoriteByUser !== -1;

    const bookmarkClick = () => {
        dispatch(toggleBookmark(pp, isBookmarked, false, false, false, userData, isLoggedIn, history));
    };

    return (
        checkNested(pp, 'status') === LOT_VISIBILITY.published.id && (
            <button
                className={classnames('btn-only-icon position-relative', { 'is-on': isBookmarked })}
                id="bookmark-classified"
                title={i18n.__('Add to bookmarks')}
                data-test="bookmark-classified"
                onClick={(e) => {
                    e.preventDefault();
                    if (isFetching) {
                        return false;
                    }
                    bookmarkClick(pp, isBookmarked, false, false, false, userData);
                }}
            >
                <i className={
                    classnames('pp-landing', {
                        'pp-heart': isBookmarked,
                        'pp-heart-o': !isBookmarked
                    })
                }
                />
                {checkNested(pp.favorites, 'count') > 0 && (
                    <span className="badge badge-bookmarks bookmarks-count">{checkNested(pp.favorites, 'count')}</span>
                )}
            </button>
        )
    );
};

export default PpBookmarkButton;
