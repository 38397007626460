import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { API_URLS } from 'CONFIG/API_URLS';
import qs from 'qs';
import { apiAction } from 'ACTIONS/api';
import { PP_TYPE } from 'MODULES/PP_TYPE';
// import { shuffleFilesIfFirstImageDuplicates } from 'MODULES/shuffleFilesIfFirstImageDuplicates';
import { setLotListCount } from 'ACTIONS/pp_list/setLotListCount';
import { setLotList } from 'ACTIONS/pp_list/setLotList';
import { setPpsListById } from 'ACTIONS/pp_list/setPpsListById';
import { GET_PPS_LIST } from 'ACTIONS';
import { checkUserCookie } from 'ACTIONS/user/checkUserCookie';
import omit from 'lodash/omit';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';


export const getPpList = (url, queryString, location, ppType, onSuccess) => (dispatch, getState) => {
    const filtersAsObject = getParamsFromSearch(`?${queryString}`);

    let getUrl = `${url}?${queryString || ''}`;

    if (filtersAsObject.multipleCategoryString) {
        if (url.indexOf('companies') !== -1 || url.indexOf('user') !== -1) {
            getUrl = `${url}?${qs.stringify(omit(qs.parse(queryString), 'multipleCategoryString')) || ''}`;
        } else {
            getUrl = `${API_URLS.CATEGORY_LOTS(filtersAsObject.multipleCategoryString)}?${qs.stringify(omit(filtersAsObject, 'multipleCategoryString'), { arrayFormat: 'brackets' }) || ''}`;
        }
    }

    const state = getState();
    dispatch(setIsFetching(`${location}?${queryString}`, true));
    if (state.ui.isFetching[`${location}?${queryString}`] && url.indexOf('map') === -1) return;
    dispatch(
        apiAction(
            {
                url: getUrl,
                auth: ppType === PP_TYPE.myCompany || ppType === PP_TYPE.my || ppType === PP_TYPE.bookmarks,
                withCredentials: ppType === PP_TYPE.myCompany || ppType === PP_TYPE.my || ppType === PP_TYPE.bookmarks,
                onSuccess: payload => () => {
                    const { content } = payload;
                    const { count, data } = content || { count: 0, data: {} };
                    const lotList = map(data, lot => lot.id);
                    // shuffleFilesIfFirstImageDuplicates(data);
                    dispatch(setLotListCount(count, queryString, location, ppType));
                    dispatch(setLotList(ppType === PP_TYPE.mapList ? data : lotList, queryString, location, ppType));
                    dispatch(setPpsListById(keyBy(data, 'id')));
                    if (onSuccess) {
                        onSuccess(data);
                    }
                    dispatch(checkUserCookie());
                    dispatch(setIsFetching(`${location}?${queryString}`, false));
                },
                label: GET_PPS_LIST
            }
        )
    );
};
