import { CONTACT_TYPES, FILTER_DISPLAY_TYPE, NUMBER_REGEX, TIME_ONE_DAY, TIME_ONE_HOUR, TIME_ONE_MINUTE } from 'CONST';
import productionConfig from 'CONFIG/env/production';

const process = { env: __DOTENV__ || window.env };

// FYI for jest test we use typeof localStorage !== 'undefined'


if (window.location.hostname.indexOf('pp.lv') !== -1) {
    window.env = productionConfig;
}

/* API config */
export const PRODUCTION_API = 'https://apipub.pp.lv';
export const HOST = window.env ? (window.env.FRONTEND_URL || window.location.origin) : (process.env.FRONTEND_URL || __HOST__);
export const MAIN_ENVIRONMENT_HOST = window.env ? window.env.FRONTEND_URL : (process.env.FRONTEND_URL || __HOST__);
export const FILES_API = window.env ? window.env.FILES_STORAGE_HOST : (process.env.FILES_STORAGE_HOST || __FILES__);
export const MESSAGING_API = window.env ? window.env.MESSAGING_URL_PUB : ((process.env.MESSAGING_URL_INT) || process.env.MESSAGING_URL_PUB || __MESSAGING__);
export const DEFAULT_LANG = __DEFAULT_LANG__;
export const LANG_LIST = __LANG_LIST__;
export const LANG_NAMES = __LANG_NAMES__;
export const API_PARAMS = '/api_user/v1/';
export const MESSAGING_API_PARAMS = '/api/v1/';
export const CALLBACK_URI = `${HOST}/my/social`;
export const CALLBACK_PROFILE = `${HOST}/my/profile`;
export const SITE_DATA = __SITE_DATA__;
export const GOOGLE_MAPS_API_KEY = window.env ? window.env.GOOGLE_MAPS_API_KEY : (process.env.GOOGLE_MAPS_API_KEY || __GOOGLE_MAPS_API_KEY__);
export const GOOGLE_CLIENT_ID = window.env ? window.env.GOOGLE_CLIENT_ID : (process.env.GOOGLE_CLIENT_ID || __GOOGLE_CLIENT_ID__);
export const INBANK_SHOP_ID = 'cce0ee65-74dd-4117-bbec-bbff7d476b41';
export const INBANK_ACCESS_TOKEN = 'e78c7ab7b25e41b07e2afb110a6fd14a';

export const EXCLUDE_PRICE_TYPES = [15];

export const ROUTE_TTL = TIME_ONE_HOUR;

// Environments
export const ENVIRONMENT = {
    localhost:       window.env ? window.env.DEV_SERVER === '1' : ((process.env.DEV_SERVER === '1') || (__DEV_SERVER__ === '1')),
    development:     window.env ? window.env.NODE_ENV === 'development' : ((process.env.NODE_ENV === 'development') || (__ENV__ === 'development')),
    production:     window.env ? window.env.NODE_ENV !== 'development' : ((process.env.NODE_ENV !== 'development') || (__ENV__ !== 'development'))
};

export const HIDE_SUBSCRIPTION_MODAL = ['#signup', '#recovery', '#signin'];

export const SOCIAL_URLS = {
    FACEBOOK: 'https://www.facebook.com/sharer/sharer.php?u=',
    X: 'https://twitter.com/intent/tweet?url=',
    LINKEDIN: 'https://www.linkedin.com/sharing/share-offsite/?url=',
    PINTEREST: 'https://www.pinterest.com/pin/create/button/?url=',
    DRAUGIEM: 'http://www.draugiem.lv/say/ext/add.php?title=',
    WHATSAPP: 'https://api.whatsapp.com/send?text='
};

export const SOCIAL_MESSENGERS = {
    whatsapp: 'https://wa.me/+',
    telegram: 'https://t.me/+',
    facebook: 'https://m.me/'
};

export const SOCIAL_ICONS = {
    whatsapp: 'icon-whatsapp',
    telegram: 'icon-telegram',
    facebook: 'icon-messenger',
    x: 'icon-x',
    linkedin: 'icon-linkedin',
    pinterest: 'icon-pinterest',
    draugiem: 'icon-draugiem',
    mail: 'icon-mail'
};

// OAuth
export const OAUTH_URL = `${window.env.OAUTH_URL}/connect/oauth2`;
export const OAUTH_INBOX_ONE_TAP_URL = `${window.env.OAUTH_URL}/connect/inbox_onetap`;
export const OAUTH_GOOGLE_ONE_TAP_URL = `${window.env.OAUTH_URL}/connect/google_onetap`;
export const OAUTH_CONNECT = provider => `${window.env.OAUTH_URL}/connect/${provider}`;
export const OAUTH_PARAMS = (params = {}) => ({
    client_id:       window.env ? window.env.OAUTH_ID : (process.env.OAUTH_ID || __OAUTH_ID__),
    client_secret:   window.env ? window.env.OAUTH_SECRET : (process.env.OAUTH_SECRET || __OAUTH_SECRET__),
    scope:           window.env ? window.env.OAUTH_SCOPE : (process.env.OAUTH_SCOPE || __OAUTH_SCOPE__),
    ...params
});

export const INBOX_ONE_TAP_URL = (email, token) => `${OAUTH_INBOX_ONE_TAP_URL}?scope=${OAUTH_PARAMS().scope}&client_id=${OAUTH_PARAMS().client_id}&client_secret=${OAUTH_PARAMS().client_secret}&email=${email}&token=${token}&redirect_uri=${CALLBACK_URI}`;
export const GOOGLE_ONE_TAP_URL = token => `${OAUTH_GOOGLE_ONE_TAP_URL}?scope=${OAUTH_PARAMS().scope}&client_id=${OAUTH_PARAMS().client_id}&client_secret=${OAUTH_PARAMS().client_secret}&token=${token}&redirect_uri=${CALLBACK_URI}`;
export const ACCOUNTS_GOOGLE_URL = `https://accounts.google.com/gsi/status?client_id=${GOOGLE_CLIENT_ID}`;

export const OAUTH_SOCIAL_PARAMS = (token) => {
    const params = [
        { name: 'client_id', value: OAUTH_PARAMS().client_id },
        { name: 'client_secret', value: OAUTH_PARAMS().client_secret },
        { name: 'scope', value: OAUTH_PARAMS().scope },
        { name: 'redirect_uri', value: token ? CALLBACK_PROFILE : CALLBACK_URI }
    ];

    if (token) params.push({ name: 'current_token', value: token });

    return params;
};

export const CLASSIFIEDS_COUNT_MOBILE_INFINITY = 4;
export const CLASSIFIEDS_PER_PAGE_HOME_SMALL = 6;
export const CLASSIFIEDS_PER_PAGE = 20;
export const CLASSIFIEDS_PER_PAGE_SMALL = 10;
export const SIMILAR_ITEMS_PER_PAGE = 24;
export const DEFAULT_DISCOUNT_PERCENT = 10;

/* Timeouts and delays in user profile */
export const CONTACTS_TTL = CONTACT_TYPES ? {
    [CONTACT_TYPES.EMAIL]: {
        ttl: TIME_ONE_DAY,
        delay: TIME_ONE_MINUTE
    },
    [CONTACT_TYPES.PHONE]: {
        ttl: TIME_ONE_HOUR,
        delay: 5 * TIME_ONE_MINUTE
    }
} : {};
export const USER_PASSWORD_TTL = TIME_ONE_DAY;
export const USER_PASSWORD_DELAY = TIME_ONE_MINUTE;
export const USER_MEMBER_PENDING_TTL = 4 * TIME_ONE_HOUR;
/* Default debounce timeout */

export const COUNTRY_PHONE_PREFIX = '371';
/* Default debounce timeout */
export const LV_MOBILE_COUNTRY_CODE_DATA = { name: 'Latvia', dialCode: '371', countryCode: 'lv', format: '+... .. ... ...' };
export const IS_MOBILE = (value) => {
    const phoneRegex = /^\d{8}$/;
    return phoneRegex.test(value);
};


export const DEFAULT_DEBOUNCE = 2000;
export const DEFAULT_COOKIE_EXPIRE = 365;
export const PICTURE_CHECK_DEBOUNCE = DEFAULT_DEBOUNCE;
export const TOKEN_CHECK_DEBOUNCE = DEFAULT_DEBOUNCE;

export const STORAGE = {
    subscribe: 'subscribe',
    scammers: 'scammers',
    adultContent: 'adultContent',
    referrer: 'referrer',
    ads: 'ads',
    serviceWorker: 'serviceWorker',
    classifiedsView: 'classifieds_view',
    login: 'login',
    visitedLots: 'visitedLots',
    watchHistory: 'watchHistory',
    language: 'language',
    watch: 'watch',
    tokensShort: 'tokensShort',
    tokensLong: 'tokensLong',
    deletedHints: 'deletedHints',
    lastAddedLot: 'lastAddedLot',
    moreToLovePage: 'moreToLovePage',
    hideInboxOneTap: 'hideInboxOneTap',
    suggestion: 'suggestion',
    gdpr: 'gdpr',
    lastEditedLot: 'lastEditedLot',
    theme: 'bs-theme',
    messagingInfo: 'messagingInfo'
};

/* PAYMENT CONFIG */
export const PAYMENT_CALLBACK = {
    successUrl: `${HOST}/my/subscription/pending`,
    cancelUrl: `${HOST}/my/subscription/cancelled`
};

// Contacts
export const SUPPORT = {
    MAIL: 'help@pp.lv',
    PHONE: '29889922'
};

// CURRENCY
export const CURRENCY = ' €';

/* ERROR HANDELING */
export const HANDLED_ERRORS = [400, 403, 404, 409]; // Errors which go to "success" part (not to "catch") for future handling.

/* IMG SIZES */
export const UPLOAD_IMG_THUMBS = {
    width: 200,
    height: 200,
    min_shortest: 600,
    min_longest: 800
};

export const HTML_HEAD_DATA = {
    title: SITE_DATA.nameShort,
    titleLong: SITE_DATA.nameLong,
    slogan: SITE_DATA.slogan
};

export const MESSAGING_CONF = {
    small_messaging_mpp: 20, // Uses in small messaging messages per page,
    messaging_sort: 'DESC', // Sort Order for messaging
    get_messages_interval: 5000, // Interval for get messages for thread
    get_unread_total_interval: 5000, // Interval for get header new messages
    get_item_threads_interval: 5000 // Interval for thread refresh
};

export const INTERVALS = {
    picture_versions: 5000,
    transactionPending: 5000
};

// -> Set "submenu: true" to show icon in horizontal lot menu (currently only in bookmark page)
// -> Set "unactive: true" to disable functionality for item
// Lot statuses - 'delete', 'edit', 'close', 'share', 'bargain', 'messaging', 'publish', 'remove';
// Lot statuses to implement - 'copy', 'promote', 'renew';

export const UI_HASH = ['user', 'password', 'subscription', 'team', 'company', 'invoices', 'settings', 'companyData'];

export const NOTIFICATION_POSITION = 'top-right';

export const NOTIFICATION_OK = 'success';

export const NOTIFICATION_ERROR = 'error';

export const FILTER_VALIDATION_REGEX = {
    [FILTER_DISPLAY_TYPE.number_int]: NUMBER_REGEX,
    [FILTER_DISPLAY_TYPE.float]: NUMBER_REGEX,
    [FILTER_DISPLAY_TYPE.number_gradual]: NUMBER_REGEX,
    [FILTER_DISPLAY_TYPE.number]: NUMBER_REGEX
};

export const IMAGE_PANORAMA_TYPE = 2;

export const OPEN_STREET_MAP_URL = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';

export const OPEN_STREET_MAP_URL_PP = 'https://map.pp.lv/tile/{z}/{x}/{y}.png';

export const OPEN_STREET_CONFIG = {
    general:  window.env.USE_MAP_PP === 1 ? OPEN_STREET_MAP_URL_PP : OPEN_STREET_MAP_URL,
    fallback: window.env.USE_MAP_PP === 1 && OPEN_STREET_MAP_URL
};


export const MAP_RADIUS = 750;
export const MAP_RADIUS_SMALL = 220;
export const MIN_CATEGORY_COUNT_ON_PAGE = 8;
export const FEED_LOG_LEVEL = {
    info: 1,
    notice: 2,
    warning: 3,
    error: 4,
    critical: 5
};

export const FILTERS_AVAILABLE_FOR_SORT = [20, 22, 23, 121, 123, 125, 143];
export const LOT_COUNT_IN_COLLAPSE = 6;
export const TRANSPORT_STOPS_COUNT_IN_COLLAPSE = 4;
export const INSTITUTION_COUNT_IN_COLLAPSE = 6;
export const SHOPS_COUNT_IN_COLLAPSE = 2;
export const REVIEW_COUNT_IN_COLLAPSE = 5;

export const ROUTE_TRANSPORT_TYPE = {
    bus_highway: 0,
    bus: 1,
    trolley: 2,
    tram: 3,
    train: 4
};


export const DEEPEST_CATEGORY_ICON_LEVEL = 2;


export const HIDDEN_CATEGORIES_IN_CREATE = [11349, 11291];

export const CATEGORIES_ADDITIONAL_CLASS = {
    11349: 'christmas-category'
};

export const CATEGORIES_WITH_NEW_LANDING = [11349, 11351, 11353, 11355, 11357];

export const CATEGORIES_WITH_DISABLED_HINTS = [11349, 11351, 11353, 11355, 11357];

export const CATEGORIES_WITH_DISABLED_SORT = [11349, 11351, 11353, 11355, 11357];
export const TRANSLATION_FOR_OTHER = { lv: 'Cits', ru: 'Другое' };
export const STATISTICS_DATE_TIME_FORMAT = {
    all: { year: '2-digit', month: 'short', day: '2-digit' },
    week: { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }
};


export const COMPANY_DESCRIPTION_LENGTH = 300;
