import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import reverse from 'lodash/reverse';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import set from 'lodash/set';

import {
    ADD_LOT_DISCOUNT, ADD_LOT_POSITION,
    ADD_SUBSCRIBER, ADD_TO_FAVORITES,
    BUILD_CATEGORY_PARENTS, CATEGORY_HINTS,
    CHANGE_HOME_ENABLED_CATEGORIES,
    CHECK_SUBSCRIBERS, CLEAR_CURRENT_CATEGORY, CLEAR_LOT_FILES,
    CLEAR_LOT_MEMBER_CONTACTS, DECREMENT_PP_FAVORITE, DECREMENT_PP_FAVORITE_IN_LIST,
    FETCHING_CLASSIFIED_MESSAGES,
    GET_CATEGORIES_FULL_TREE,
    GET_CATEGORIES_WITH_PARENTS,
    GET_CATEGORY_DATA, GET_FAVORITES_COUNT,
    GET_INFINITE_LIST,
    GET_LOT_AMENITIES,
    GET_LOT_FILES_CLASSIFIED,
    GET_LOT_MY_REVIEWS,
    GET_LOT_REVIEWS, GET_LOT_THREAD_COUNT,
    GET_LOTS_BY_BUILDING,
    GET_MEMBER_CONTACTS,
    GET_MESSAGES_FOR_LOT,
    GET_POPULAR_REGIONS_TREE,
    GET_PP,
    GET_PPS_ARRAY,
    GET_PPS_LIST,
    GET_PPS_LIST_BY_ID,
    GET_SIMILAR_PP,
    GET_SMALL_CLASSIFIED_LIST, INCREMENT_PP_FAVORITE, INCREMENT_PP_FAVORITE_IN_LIST, INCREMENT_THREAD_COUNT,
    INITIAL_PP_STATE,
    INITIAL_PP_STATE_BY_TYPE, REMOVE_FROM_FAVORITES,
    SET_ACTIVE_PAGE,
    SET_CATEGORIES_HISTORY, SET_PP_HELMET_DATA, SET_PP_LIST,
    UPDATE_CLASSIFIED_STATUS, UPDATE_LIST_LOT, UPDATE_LOT_HIGHLIGHT, UPDATE_LOT_PUBLISH, UPDATE_PP_LIST_COUNT
} from 'ACTIONS';
import { OTHER_CATEGORY_NAME } from 'CONST';
import Storage from 'HOC/storage';
import { LANG_LIST, STORAGE } from 'CONFIG';
import { mapPPToLocation } from 'MODULES/mapPPToLocation';
import { checkNested } from 'MODULES/checkNested';
import { setIn } from 'MODULES/setIn';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import remove from 'lodash/remove';


let categoriesBySlug = {};
let categoriesById = {};

const rootCategories = window.categories;

forEach(rootCategories.content.children, (cat) => {
    categoriesBySlug = { ...categoriesBySlug, [cat.slug.ru]: cat.id, [cat.slug.lv]: cat.id };
    categoriesById = { ...categoriesById, [cat.id]: cat };
});


const INITIAL_STATE = {
    categories: {
        byId: categoriesById,
        bySlug: categoriesBySlug,
        root: rootCategories.content.children },
    pp: {},
    categoryData: null,
    ppsByCoordinates: {},
    categoryDataById: null,
    ppsList: {
        [PP_TYPE.list]: { default: {} },
        [PP_TYPE.myCompany]: { default: {} },
        [PP_TYPE.publicCompany]: { default: {} },
        [PP_TYPE.myCompanyLotsXML]: { default: {} },
        [PP_TYPE.bookmarks]: { default: {} },
        [PP_TYPE.recently_added]: { default: {} },
        [PP_TYPE.infinite]: { default: {} },
        [PP_TYPE.my]: { default: {} },
        [PP_TYPE.smallPpsList]: { default: {} }
    },
    lotPosition: {},
    smallPpsList: {},
    ppsListCount: {},
    ppsLocation: {},
    activePage: {},
    similarItems: {},
    lotsByBuilding: {},
    lotAmenities: {},
    lotMembers: {},
    reviews: {},
    myReview: {},
    categoriesFullTree: {},
    popularRegionsTree: {},
    hints: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SMALL_CLASSIFIED_LIST:
            const lastLotData = Storage.get(STORAGE.lastAddedLot, true, true);
            const lastLotSlug = checkNested(lastLotData, ['lot', 'category', 'slug', action.payload.lang]);
            return {
                ...state,
                ppsList: {
                    ...state.ppsList,
                    [PP_TYPE.smallPpsList]: {
                        ...state[PP_TYPE.smallPpsList],
                        [action.path]: action.ppType === PP_TYPE.infinite && lastLotData && (lastLotSlug.split('/')[0] === action.payload.category.split('/')[0] || action.path.replace(`_${action.ppType}`, '') === `/${action.payload.lang}`)
                            ? { 0: lastLotData.lot, ...action.payload.lots }
                            : action.payload.lots
                    }
                }
            };
        case SET_CATEGORIES_HISTORY:
            return action.payload;
        case GET_CATEGORIES_WITH_PARENTS:
            return { ...state,
                categories: {
                    ...state.categories,
                    byId: { ...state.categories.byId, ...action.payload.byId },
                    bySlug: { ...state.categories.bySlug, ...action.payload.bySlug }
                } };
        case GET_PPS_LIST:
            let { filterType } = action;
            if (checkNested(state.ppsList[action.listType], action.activePath)) {
                filterType = action.activePath;
            }
            let ppList = action.listType === PP_TYPE.mapList || action.listType === PP_TYPE.mapListPp ? action.payload.data : keyBy(action.payload.data, o => `id-${o.id}`);
            ppList = action.listType === PP_TYPE.infinite ? keyBy(reverse(action.payload.data), o => `id-${o.id}`) : ppList;
            return {
                ...state,
                ppsList: {
                    ...state.ppsList,
                    [action.listType]: {
                        ...state.ppsList[action.listType],
                        [filterType]:
                            {
                                ...checkNested(state.ppsList[action.listType], [filterType], {}),
                                [action.page]: {
                                    ...checkNested(state.ppsList[action.listType], [filterType, action.page], {}),
                                    [action.categoryId]: ppList
                                }
                            }
                    }
                },
                ppsListCount: {
                    ...state.ppsListCount,
                    [action.listType]: {
                        ...state.ppsListCount[action.listType],
                        [filterType]: action.payload.count
                    }
                },
                ppsLocation: action.payload.count && action.payload.data ? mapPPToLocation(state.ppsLocation, action.payload.data) : {}
            };
        case SET_PP_HELMET_DATA:
            return { ...state, pp: { ...state.pp, [action.id]: { ...checkNested(state.pp, action.id, {}), helmet: action.helmet } } };
        case GET_PP:
            return { ...state, pp: { ...state.pp, [action.payload.id]: { ...action.payload, helmet: action.helmet } } };
        case GET_FAVORITES_COUNT:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    ...setIn([action.lotId], {
                        ...state.pp[action.lotId],
                        favorites: action.favorites || []
                    })
                }
            };
        case GET_LOT_THREAD_COUNT:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    ...setIn([action.lotId], {
                        ...state.pp[action.lotId],
                        threadCount: action.threadCount
                    })
                }
            };
        case SET_PP_LIST:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    ...action.payload
                }
            };
        case ADD_TO_FAVORITES:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    [action.lotId]: {
                        ...state.pp[action.lotId],
                        ...setIn('favorites', {
                            ...state.pp[action.lotId].favorites,
                            count: state.pp[action.lotId].favorites.count ? state.pp[action.lotId].favorites.count + 1 : 1,
                            data: state.pp[action.lotId].favorites.data ? [...state.pp[action.lotId].favorites.data, action.payload] : [action.payload]
                        })
                    }
                }
            };
        case REMOVE_FROM_FAVORITES:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    [action.lotId]: {
                        ...state.pp[action.lotId],
                        ...setIn('favorites', {
                            ...state.pp[action.lotId].favorites,
                            count: state.pp[action.lotId].favorites.count - 1,
                            data: remove(state.pp[action.lotId].favorites.data, user => user.id !== action.payload.id)
                        })
                    }
                }
            };
        case INCREMENT_THREAD_COUNT:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    ...setIn([action.lotId], {
                        ...state.pp[action.lotId],
                        threadCount: state.pp[action.lotId].threadCount + 1
                    })
                }
            };
        case INCREMENT_PP_FAVORITE:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    ...setIn([action.payload.id], {
                        ...state.pp[action.payload.id],
                        ...action.payload,
                        favoriteCount:  checkNested(state.pp, [action.payload.id, 'favoriteCount'], 0) + 1,
                        isBookmarked: true
                    }, state.pp)
                }
            };
        case UPDATE_LIST_LOT:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    ...setIn([action.payload.id], action.payload, state.pp)
                }
            };


        case DECREMENT_PP_FAVORITE:

            return {
                ...state,
                pp: {
                    ...state.pp,
                    ...setIn([action.payload.id], {
                        ...action.payload,
                        favoriteCount: action.payload.favoriteCount - 1,
                        favorites: { ...checkNested(state.pp, [action.payload.id, 'favorites']), data: filter(checkNested(state.pp, [action.payload.id, 'favorites', 'data']), (o) => (o.id !== action.userId)) },
                        isBookmarked: false
                    }, state.pp)
                }
            };
        case INCREMENT_PP_FAVORITE_IN_LIST:
            return {
                ...state,
                ppsList: {
                    ...state.ppsList,
                    ...setIn(action.activePage
                        ? `${action.lotsPath[0]}.${action.lotsPath[1]}.${action.activePage}.${PP_TYPE.list}.id-${action.payload.id}`
                        : `${action.lotsPath[0]}.${action.lotsPath[1]}.id-${action.payload.id}`, {
                        ...action.payload,
                        favoriteCount: action.payload.favoriteCount + 1
                    }, state.ppsList)
                }
            };
        case DECREMENT_PP_FAVORITE_IN_LIST:
            return {
                ...state,
                ppsList: {
                    ...state.ppsList,
                    ...setIn(action.activePage
                        ? `${action.lotsPath[0]}.${action.lotsPath[1]}.${action.activePage}.${PP_TYPE.list}.id-${action.payload.id}`
                        : `${action.lotsPath[0]}.${action.lotsPath[1]}.id-${action.payload.id}`, {
                        ...action.payload,
                        favoriteCount: action.payload.favoriteCount - 1
                    }, state.ppsList)
                }
            };
        case GET_PPS_LIST_BY_ID:
            if (action.bookmarkIds.length > 0) {
                action.bookmarkIds.forEach(bookmark => {
                    set(action.payload[bookmark.id], 'isBookmarked', true);
                });
            }
            return { ...state, pp: { ...state.pp, ...action.payload } };
        case GET_PPS_ARRAY:
            return { ...state,
                ppsByCoordinates: { ...state.ppsByCoordinates,
                    [action.coordinates]: action.payload } };
        case GET_INFINITE_LIST:
            return { ...state,
                ppsList: {
                    ...state.ppsList,
                    [action.dispatcher]: {
                        ...checkNested(state.ppsList, action.dispatcher, {}),
                        ...action.list
                    }
                },
                ppsListCount: {
                    ...state.ppsListCount,
                    [action.listType]: {
                        ...checkNested(state.ppsListCount, action.listType, {}),
                        [action.dispatcher]: action.count
                    }
                }
            };
        case GET_LOT_FILES_CLASSIFIED:
            return {
                ...state,
                pp: { ...state.pp, [action.classified]: { ...state.pp[action.classified], files: action.payload } }
            };
        case GET_MEMBER_CONTACTS:
            return { ...state, lotMembers: { ...state.lotMembers, [action.payload.id]: action.payload } };
        case UPDATE_CLASSIFIED_STATUS:
            return { ...state, ppsList: setIn(action.path, action.payload, state.ppsList) };
        case CLEAR_CURRENT_CATEGORY:
            return { ...state, categories: { ...state.categories, currentCategory: null } };
        case GET_CATEGORY_DATA: {
            let allCategories = [];
            forEach(mapValues(action.payload.children, parent => (action.disableChildrens ? [] : parent.children)), (nestedParent) => {
                forEach(nestedParent, (child) => {
                    allCategories = [...allCategories, child];
                });
            });

            let { bySlug } = state.categories;
            if (action.payload.category) {
                LANG_LIST.forEach((l) => {
                    bySlug = {
                        ...bySlug,
                        ...setIn(action.payload.category.slug[l], action.payload.category.id, state.categories.bySlug),
                        ...mapValues(keyBy(action.payload.children, o => o.slug[l]), 'id'),
                        ...mapValues(keyBy(action.payload.parents, o => o.slug[l]), 'id')
                    };
                });
            } else {
                LANG_LIST.forEach((l) => {
                    bySlug = {
                        ...bySlug,
                        ...mapValues(keyBy(action.payload.children, `slug.${l}`), 'id'),
                        ...mapValues(keyBy(allCategories, `slug.${l}`), 'id') };
                });
            }
            return {
                ...state,
                categories: action.payload.category ? {
                    root: state.categories.root,
                    byId: {
                        ...keyBy(action.payload.children, 'id'),
                        ...keyBy(action.payload.parents, 'id'),
                        ...setIn(action.payload.category.id, {
                            parents: action.payload.parents,
                            children: action.payload.children,
                            childrenGroupedByLetter: action.payload.childrenGroupedByLetter,
                            ...action.payload.category
                        }, state.categories.byId)
                    },
                    bySlug,
                    currentCategory: {
                        ...action.payload.category,
                        parents: action.payload.parents,
                        children: sortBy(values(action.payload.children), [o => OTHER_CATEGORY_NAME.includes(o.name), o => o.name]),
                        selectedCategory: action.payload.selectedCategory
                    }
                } : {
                    root: state.categories.root,
                    byId: {
                        ...state.categories.byId,
                        ...keyBy(action.payload.children, 'id'),
                        ...keyBy(allCategories, 'id')
                    },
                    bySlug
                }
            };
        }
        case BUILD_CATEGORY_PARENTS:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    byId: {
                        ...state.categories.byId,
                        [action.payload.id]: {
                            ...state.categories.byId[action.payload.id],
                            parents: action.payload.parents
                        }
                    }
                }
            };
        case SET_ACTIVE_PAGE:
            return { ...state, activePage: setIn(action.dispatchID, action.payload, state.activePage) };
        // case DELETE_PP:
        //     return state
        //     // return {
        //     //     ...state,
        //     //     ppsList: omit(state.ppsList, `${action.lotsPath.ppType}.${action.lotsPath.pathname}.${action.queryString}.${action.payload}`),
        //     //     ppsListCount: setIn(action.lotsPath.filter((p, i) => i < 2), checkNested(state.ppsListCount, action.lotsPath.filter((p, i) => i < 2), 1) - 1, state.ppsListCount)
        //     // };
        case ADD_LOT_DISCOUNT:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    ...setIn(action.payload.id, {
                        ...checkNested(state.pp, action.id),
                        discount: action.payload.discount,
                        prices: action.payload.prices
                    }, state.pp)
                }
            };
        case ADD_LOT_POSITION:
            return {
                ...state,
                lotPosition: {
                    ...state.lotPosition,
                    [action.id]: action.payload
                }
            };
        case UPDATE_LOT_PUBLISH:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    ...setIn(action.payload.id, {
                        ...checkNested(state.pp, action.id),
                        publishDate: action.payload.publishDate
                    }, state.pp)
                }
            };
        case UPDATE_LOT_HIGHLIGHT:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    ...setIn(action.payload.id, {
                        ...checkNested(state.pp, action.id, {}),
                        highlighted: action.payload.highlighted
                    }, state.pp)
                }
            };
        case GET_MESSAGES_FOR_LOT:
            return {
                ...state,
                pp: {
                    ...state.pp,
                    [action.id]: {
                        ...checkNested(state.pp, action.id),
                        messagingData: action.payload
                    }
                }
            };
        case FETCHING_CLASSIFIED_MESSAGES:
            return {
                ...state,
                ppsList: {
                    ...state.ppsList,
                    ...setIn(`${action.lotsPath[0]}.${action.lotsPath[1]}.${PP_TYPE.list}.id-${action.id}`, {
                        ...checkNested(state.ppsList, `${action.lotsPath[0]}.${action.lotsPath[1]}.${PP_TYPE.list}.id-${action.id}`),
                        isFetchingMessages: action.isFetching
                    }, state.ppsList)
                }
            };
        case INITIAL_PP_STATE:
            return {
                ...state,
                ppsList: INITIAL_STATE.ppsList,
                ppsListCount: INITIAL_STATE.ppsListCount,
                activePage: INITIAL_STATE.activePage
            };
        case UPDATE_PP_LIST_COUNT:
            return {
                ...state,
                ppsListCount: {
                    ...state.ppsListCount,
                    list: setIn(Object.keys(state.activePage)[0], action.payload, state.ppsListCount.list)
                }
            };
        case INITIAL_PP_STATE_BY_TYPE:
            return {
                ...state,
                ppsList: { ...state.ppsList, [action.listType]: INITIAL_STATE.ppsList[action.listType] },
                ppsListCount: {},
                activePage: INITIAL_STATE.activePage
            };
        case GET_SIMILAR_PP:
            return {
                ...state,
                similarItems: setIn(action.id, action.payload, state.similarItems)
            };
        case CLEAR_LOT_MEMBER_CONTACTS:
            return {
                ...state,
                lotMembers: omit(state.lotMembers, action.payload)
            };
        case CHECK_SUBSCRIBERS:
            return {
                ...state,
                subscribers: { ...state.subscribers, subscribers: action.payload }
            };
        case ADD_SUBSCRIBER:
            return {
                ...state,
                subscriber: { ...state.subscriber, subscriber: action.payload }
            };
        case GET_LOTS_BY_BUILDING:
            return {
                ...state,
                lotsByBuilding: setIn(action.lotId, action.payload, state.lotsByBuilding)
            };
        case GET_LOT_AMENITIES:
            return {
                ...state,
                lotAmenities: setIn(action.id, action.payload, state.lotAmenities)
            };
        case GET_LOT_REVIEWS:
            return {
                ...state,
                reviews: { ...state.reviews, reviews: action.payload }
            };
        case GET_LOT_MY_REVIEWS:
            return {
                ...state,
                myReview: { ...state.myReview, [action.lotId]: action.payload }
            };
        case GET_CATEGORIES_FULL_TREE:
            return {
                ...state,
                categoriesFullTree: {
                    ...state.categoriesFullTree,
                    [action.lang]: action.payload
                }
            };
        case GET_POPULAR_REGIONS_TREE:
            return {
                ...state,
                popularRegionsTree: {
                    ...state.popularRegionsTree,
                    [action.lang]: action.payload.data
                }
            };
        case CHANGE_HOME_ENABLED_CATEGORIES:
            return {
                ...state,
                homeDisabledCategories: action.value ? omit(state.homeDisabledCategories, action.category) : { ...state.homeDisabledCategories, [action.category]: { category: { id: action.category } } }
            };
        case CLEAR_LOT_FILES:
            let brokenImageIndex = false;
            forEach(state.pp[action.id].files, (file, index) => {
                filter(file.file.versions, (version) => {
                    if (action.imageSrc.includes(version.path)) {
                        brokenImageIndex = index;
                    }
                });
            });
            return {
                ...state,
                pp: {
                    ...state.pp,
                    [action.id]: {
                        ...checkNested(state.pp, action.id),
                        files: brokenImageIndex !== false ? omit(state.pp[action.id].files, brokenImageIndex) : { ...state.pp[action.id].files }
                    }
                }
            };
        case CATEGORY_HINTS:
            return {
                ...state,
                hints: { ...state.hints, [action.category]: action.payload }
            };
        default:
            return state;
    }
}
