import ppFallback from 'IMAGES/pp_fallback.png';
import { HOST, HTML_HEAD_DATA, SITE_DATA } from './index';

export const defaultMeta = {
    title: { subTag: 'name', defaultValue: HTML_HEAD_DATA.slogan },
    description: { subTag: 'name', defaultValue: HTML_HEAD_DATA.titleLong },
    'og:description': { subTag: 'property', defaultValue: HTML_HEAD_DATA.slogan },
    'og:title': { subTag: 'property', defaultValue: HTML_HEAD_DATA.titleLong },
    'twitter:title': { subTag: 'name', defaultValue: HTML_HEAD_DATA.titleLong },
    'og:url': { subTag: 'property', defaultValue: HOST },
    'og:site_name': { subTag: 'property', defaultValue: SITE_DATA.nameShort },
    property: { subTag: 'property', defaultValue: 'summary' },
    'og:image': { subTag: 'property', defaultValue: ppFallback },
    'og:type': { subTag: 'property', defaultValue: 'website' },
    customDescription: { subTag: 'name', defaultValue: 'Pirkšanas un pārdošanas platforma, Bezmaksas sludinājumi, jaunas un lietotas preces, Latvijā un Rīgā' }
    /*
       * Default Meta tags for ALL pages
    */
};
