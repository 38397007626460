import { imgSizes } from 'CONST';
import { FILES_API } from 'CONFIG';

export const buildImgUrl = (file, imgSize, hash) => {
    if (!file || typeof file.storageId === 'undefined') return null;
    let size = imgSize;
    if (!file.converted && file.outputType === 'video') {
        size = imgSizes.original;
    }
    const isImage = file.outputType === 'image';
    if (isImage && size === imgSizes.original) {
        size = imgSizes.aspect34;
    }
    const id = file.storageId;

    let extension;
    if (file.outputType === 'video') {
        extension = file.extension && imgSizes[size] === 'original' ? `.${file.extension}` : (isImage ? '.jpeg' : imgSizes[size] === 'preview' ? '.webp' : `.${file.extension}`);
    } else {
        extension = imgSizes[size] === 'preview' ? '.webp' : `.${file.extension}`;
    }

    const rotationSlug = {
        1: '_90',
        2: '_180',
        3: '_270'
    };

    return `${FILES_API}/storage/${id.slice(0, 2)}/${id.slice(2, 4)}/${id}/${imgSizes[size]}${file.rotation ? rotationSlug[file.rotation] : ''}${extension}${hash ? `?${hash}` : ''}`;
};
