import { createSelector } from 'reselect';
import { STORAGE } from 'CONFIG';
import Storage from 'HOC/storage';
import { checkNested } from 'MODULES/checkNested';
import sortBy from 'lodash/sortBy';


export const getSelectedLanguage = createSelector(
    [state => state.general.selectedLang],
    language => (language)
);


export const getPartnersSelector = createSelector(
    [state => state.general.partners, (state, category) => category],
    (partners, category) => (sortBy(checkNested(partners, category), 'name'))
);



export const isMessagingOn = createSelector(
    [state => state.general.apiStatus],
    apiStatus => apiStatus.msg
);

export const apiNeedPaymentSelector = createSelector(
    [state => state.general.apiNeedPayment],
    apiNeedPayment => apiNeedPayment
);


export const watchIdSelector = createSelector(
    [state => state.general.watchToken],
    watchToken => watchToken || Storage.get(STORAGE.watch)
);

export const getHistoryByKeySelector = createSelector(
    state => state.general.locationHistory,
    (state, props) => props.location,
    (allHistory, location) => checkNested(allHistory, [location.pathname, location.key])
);
