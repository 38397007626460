import React from 'react';
import ButtonShare from 'COMPONENTS/social_share/index';
import { isMobile } from 'react-device-detect';
import { SOCIAL_ICONS, SOCIAL_URLS } from 'CONFIG';

const ShareSocial = ({ i18n, url, image, title, body }) => (
    <div className="py-2">
        <ButtonShare
            id="facebook-share-button"
            socialName={i18n.__('Facebook')}
            shareUrl={SOCIAL_URLS.FACEBOOK}
            ppUrl={url}
            dropdown
            ico={SOCIAL_ICONS.facebook}
            gtmEventName="lotviewShareFacebook"
        />
        <ButtonShare
            id="x-share-button"
            socialName={i18n.__('X')}
            shareUrl={SOCIAL_URLS.X}
            ppUrl={url}
            title={title}
            dropdown
            ico={SOCIAL_ICONS.x}
            gtmEventName="lotviewShareX"
        />
        <ButtonShare
            id="linkedin-share-button"
            socialName={i18n.__('Linkedin')}
            shareUrl={SOCIAL_URLS.LINKEDIN}
            ppUrl={url}
            dropdown
            ico={SOCIAL_ICONS.linkedin}
            gtmEventName="lotviewShareLinkedin"
        />
        {image
            && (
                <ButtonShare
                    id="pinterest-share-button"
                    socialName={i18n.__('Pinterest')}
                    shareUrl={SOCIAL_URLS.PINTEREST}
                    ppUrl={url}
                    image={image}
                    dropdown
                    ico={SOCIAL_ICONS.pinterest}
                    gtmEventName="lotviewSharePinterest"
                />
            )}
        <ButtonShare
            id="draugiem-share-button"
            socialName={i18n.__('Draugiem')}
            shareUrl={SOCIAL_URLS.DRAUGIEM}
            ppUrl={url}
            titleDraugiem={title}
            dropdown
            ico={SOCIAL_ICONS.draugiem}
            gtmEventName="lotviewShareDraugiem"
        />
        <ButtonShare
            id="email-share-button"
            socialName={i18n.__('By Email')}
            title={title}
            body={body}
            ppUrl={url}
            dropdown
            ico={SOCIAL_ICONS.mail}
            email
            gtmEventName="lotviewShareEmail"
        />
        {isMobile
            && (
                <ButtonShare
                    id="whatsapp-share-button"
                    socialName={i18n.__('Whatsapp')}
                    shareUrl={SOCIAL_URLS.WHATSAPP}
                    ppUrl={url}
                    dropdown
                    ico={SOCIAL_ICONS.whatsapp}
                    gtmEventName="lotviewShareWhatsapp"
                />
            )}
    </div>
);

export default (ShareSocial);
